import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';

const Slot = ({timestamp, duration}) => {
   const date = moment.unix(timestamp);
   const [dayNumber, day, month, year] = date.format('E-D-M-Y').split('-');
   const getDuration = () => {
      if(duration < 3600){
         return _.round(duration / 60, 2) + 'min';
      }
      return 0;
   }
   return (
      <>
         {moment.unix(timestamp).format('HH[h]mm')} <span>({getDuration()})</span>
      </>
   );
}

export default Slot;