import React, { useEffect, useState, useRef } from 'react';

import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import numeral from 'numeral'

import Date from './date.js'
import Slot from './slot.js'


const Upload = ({id, documents, setDocuments}) => {

   const fileInputRef = useRef(null)

   const handleFileSelect = () => {
      fileInputRef.current.click()
   }

   const handleFileChange = (event) => {
      handleFileUpload(event.target.files[0])
   }

   const handleFileUpload = (selectedFile) => {
      if (selectedFile) {
         const formData = new FormData()
         formData.append('file', selectedFile)
         axios.post('/booking/manage/upload?booking_id=' + id, formData).then(response => {
            let documentsClone = [...documents]
            documentsClone.push(response.data.file)
            setDocuments([...documentsClone])
         }).catch(error => {
            console.error('Erreur lors de l\'upload du fichier', error)
         })
      }
   }
   return (
      <div>
         <input ref={fileInputRef} style={{ display: 'none' }} type="file" onChange={handleFileChange} />
         <button className={'btn btn-default'} onClick={handleFileSelect}><span className={'icon-icon-file-upload'}></span>Ajouter un document</button>
      </div>
   )
}

const Booking = ({id, access, start, duration, files, expert, _handleClose}) => {

   const [interactionSelected, setInteractionSelected] = useState('note')
   const [documents, setDocuments] = useState([...files])

   const getStatus = () => {
      const values = {
         open: 'En live',
         soon: 'À venir',
         past: 'Passé'
      };
      return (
         <div className={'bookings__sidebar_status ' + access}>{_.get(values, access)}</div>
      );
   }

   const getDate = () => {
      return (
         <div className="bookings__sidebar_date">
            <Date timestamp={start} />
         </div>
      )
   }

   const getSlot = () => {
      return (
         <div className="bookings__sidebar_slot">
            <Slot timestamp={start} duration={duration} />
         </div>
      )
   }

   const _handleFileDelete = (document_id) => {
      axios.get('/booking/manage/upload/delete?booking_id=' + id + '&booking_file_id=' + document_id).then(response => {
         let documentsClone = [..._.filter(documents, function(doc){
            return doc.id != document_id
         })]
         setDocuments([...documentsClone])
      }).catch(error => {
         console.error('Erreur lors de l\'upload du fichier', error)
      })
   }

   const getDocuments = () => {
      return documents.map((document) => {
         return (
            <div className={'bookings__sidebar_interactions_file_item'}>
               <div className={'bookings__sidebar_interactions_file_item__name'}>
                  <span className={'icon-icon-file-attachement'}></span>
                  {document.name}
               </div>
               <div className={'bookings__sidebar_interactions_file_item__trash'} onClick={() => _handleFileDelete(document.id)}>
                  <span className={'icon-icon-trash-bin'}></span>
               </div>
            </div>
         )
      })
   }

   const getInteractions = () => {
      const interactions = [
         {state: 'note', icon: 'icon-Pen', title: 'Note', description: 'Ajouter une note pour aider  à comprendre votre problème'},
         {state: 'file', icon: 'icon-icon-document-file-clip', title: 'Documents', description: 'Ajouter une ou plusieurs pièces jointes à la visio'}
      ]
      return interactions.map(({state, icon, title, description}) => {
         return (
            <div key={state} onClick={() => setInteractionSelected(state)} className={state == interactionSelected ? 'bookings__sidebar_interactions_item active':'bookings__sidebar_interactions_item'}>
               <span className={icon}></span>
               <div className="bookings__sidebar_interactions_item_title">{title}</div>
               <div className="bookings__sidebar_interactions_item_description">{description}</div>
            </div>
         )
      })
   }

   const getInteractionContent = () => {
      if(interactionSelected == 'note') {
         return (
            <div className={'bookings__sidebar_interactions_note'}>
               <textarea name="description" id="description" cols="30" rows="10" placeholder={'Ex : nous n’arrivons pas à finaliser notre bilan comptable et nous ne trouvons pas de solutions…'}></textarea>
               <div className="submit">
                  <button className={'btn btn-default'}>Enregistrer la note</button>
               </div>
            </div>
         )
      }
      if(interactionSelected == 'file') {
         return (
            <div className={'bookings__sidebar_interactions_file'}>
               <div className={'bookings__sidebar_interactions_file_items'}>
                  {getDocuments()}
               </div>
               <Upload id={id} documents={documents} setDocuments={setDocuments} />
            </div>
         )
      }
      return null
   }

   return (
      <>
         <div className="bookings__sidebar_close" onClick={() => _handleClose()}>
            <span className={'icon-icon-close'}></span>
         </div>
         {getDate()}
         {getSlot()}
         <div className="bookings__sidebar_expert">
            <div className="media">
               <img src={'/static/' + expert.media_path} />
            </div>
            <div className="name">{_.get(expert, 'firstname')}</div>
            <div className="job">Expert Marketing</div>
         </div>
         <div className="bookings__sidebar_separator"></div>
         <div className="bookings__sidebar_link">
            <div className="bookings__sidebar_link__title">Lien de la visio</div>
            <div className="bookings__sidebar_link__input">
               https://popmentor.co/session/<span>apaod9171010</span>
            </div>
         </div>
         <div className="bookings__sidebar_actions">
            <button className={'btn btn-primary'}><span className={'icon-icon-calendar-add-plus'}></span>Ajouter à mon agenda</button>
            <button className={'btn btn-primary'}><span className={'icon-icon-calendar-chedule'}></span>Annuler le visio</button>
         </div>
         <div className="bookings__sidebar_separator"></div>
         <div className="bookings__sidebar_interactions">
            <div className="title">Préparer la visio</div>
            <div className="bookings__sidebar_interactions_items">
               {getInteractions()}
            </div>
            {getInteractionContent()}
            {/*
            <p>Afin d’optimiser votre visio et de facililter vos échanges avec votre expert, n’hésitez pas à indiquer des informations sur votre besoin.</p>
            <textarea name="description" id="description" cols="30" rows="10" placeholder={'Ex : nous n’arrivons pas à finaliser notre bilan comptable et nous ne trouvons pas de solutions…'}></textarea>
            <div className="submit">
               <button className={'btn btn-default'}>Enregistrer</button>
            </div>
            <div className="bookings__sidebar_files">
            <div className="title">Déposer des documents</div>
            <p>Si vous souhaitez partager en amont des documents pour que votre expert puisse préparer votre visio en toute sérénité</p>
         </div>
            */}
         </div>
      </>
   );

}

export default Booking;