import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

import Date from './date.js';
import Slot from './slot.js';



const Card = ({id, access, start, duration, expert, _handleBooking}) => {

   const getStatus = () => {
      const values = {
         open: 'En live',
         soon: 'À venir',
         past: 'Passé'
      };
      return (
         <div className={'bookings__item_status ' + access}>{_.get(values, access)}</div>
      );
   }

   const getDate = () => {
      return (
         <div className="bookings__item_date">
            <Date timestamp={start} />
         </div>
      );
   }

   const getSlot = () => {
      return (
         <div className="bookings__item_slot">
            <Slot timestamp={start} duration={duration} />
         </div>
      );
   }

   const getButton = () => {
      if(access == 'open'){
         return (
            <a href="#" className="btn btn-default">Rejoindre la visio</a>
         );
      }
      if(access == 'soon'){
         return (
            <button className="btn btn-secondary" onClick={() => _handleBooking(id)}>Préparer la visio</button>
         );
      }
      if(access == 'past'){
         return (
            <a href="#" className="btn btn-secondary">Télécharger la facture</a>
         );
      }
   }

   return (
      <div className={'bookings__item'} onClick={() => _handleBooking(id)}>
         <div className="bookings__item_card">
            {getStatus()}
            {getDate()}
            {getSlot()}
            <div className="bookings__item_separator"></div>
            <div className="bookings__item_expert">
               <div className="media">
                  <img src={'/static/' + expert.media_path} />
               </div>
               <div className="name">{_.get(expert, 'firstname')}</div>
               <div className="job">Expert Marketing</div>
            </div>
            <div className="bookings__item_action">
               {getButton()}
            </div>
         </div>
      </div>
   );

}

export default Card;