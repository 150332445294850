import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import moment from 'moment';

const getMonthName = (month) => {
   const months = ['Janvier','Février','Mars','Avril','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'];
   const monthName = months[month] || '';
   return _.toLower(monthName);
}

const getDayName = (dayNumber) => {
   const days = ['Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi','Dimanche'];
   const dayName = days[dayNumber] || '';
   return dayName;
}

const Date = ({timestamp}) => {
   const date = moment.unix(timestamp);
   const [dayNumber, day, month, year] = date.format('E-D-M-Y').split('-');
   return (
      <>
         {getDayName(dayNumber)} {day} {getMonthName(month)} {year}
      </>
   );
}

export default Date;