import { render, unmountComponentAtNode } from 'react-dom';
import React, { useEffect, useRef, useState, useCallback, useContext } from 'react';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment'

import Card from './components/card.js';
import Booking from './components/booking.js';

const BookingManage = ({}) => {

   const [filter, setFilter] = useState('all');
   const [bookings, setBookings] = useState([]);
   const [booking, setBooking] = useState({});

   useEffect(() => {
      axios('/booking/list.json').then((res) => {
         const {data} = res;
         setBookings(data);
      }).catch((e) => {
      });
   }, []);

   const _handleBooking = (id) => {
      axios('/booking/' + id + '.json').then((res) => {
         const {data} = res;
         setBooking(data);
      }).catch((e) => {
      });
   }

   const getTitle = () => {
      return (
         <div className={'bookings__title'}>Mes visios</div>
      );
   }

   const getTabs = () => {
      const items = [
         {name: 'Tous', value: 'all'},
         {name: 'En direct', value: 'open'},
         {name: 'À venir', value: 'soon'},
         {name: 'Passés', value: 'past'}
      ];
      const getItems = () => {
         return items.map((item) => {
            const getClass = () => {
               return item.value == filter ? 'active' : '';
            }
            return (
               <li key={item.value} className={getClass()} onClick={() => setFilter(item.value)}>{item.name}</li>
            )
         });
      }
      return (
         <div className="bookings__tabs">
            <ul>
               {getItems()}
            </ul>
         </div>
      );
   }

   const _handleClose = () => {
      setBooking({})
   }

   const getBookings = () => {

      const getState = (start) => {
         let date = moment(start * 1000)
         let currentDate = moment()
         if(date.isSame(currentDate, 'today')){
            return 'open'
         }
         if(date.isAfter(currentDate)) {
            return 'soon'
         }
         return 'past'
      }
      
      const getItems = () => {
         return bookings.map((booking) => {
            const access = getState(booking.start)
            if(filter == 'all' || filter == access){
               return <Card key={_.get(booking, 'id')} access={access} _handleBooking={_handleBooking} {...booking} />
            } else {
               return null
            }
         });
      }

      const items = getItems()

      return (
         <div className={'bookings__items'}>
            {items}
         </div>
      );
   }

   const getBooking = () => {
      if(!_.isEmpty(booking)){
         return (
            <>
               <div className="bookings__sidebar active">
                  <Booking _handleClose={_handleClose} {...booking} />
               </div>
               <div className="bookings__overlay" onClick={() => _handleClose()}></div>
            </>
         );
      }
      return (
         <>
            <div className="bookings__sidebar"></div>
         </>
      );
   }

   return (
      <>
         {getBooking()}
         {getTitle()}
         {getTabs()}
         {getBookings()}
      </>
   );

}

class BookingManageElement extends HTMLElement {
   connectedCallback() {
      const booking = this.dataset.booking || false;
      render(
         <BookingManage
            product={booking?JSON.parse(booking):{}}
         />, this
      );
   }

   disconnectedCallback() {
      unmountComponentAtNode(this);
   }
}

customElements.define('booking-manage-element', BookingManageElement);